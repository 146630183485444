import angular from 'angular';
import app from './js/app';
import help from './js/modals/help';
import moment from 'moment';
import { getDateFormatConfigForLocale } from './js/helpers';
import './js/services';
import './js/controllers';
import './js/directives';
import './js/filters';
import { setDefaultLegislationCode, getDefaultLegislation } from '../common/helpers/legislations';

app
  .run([
    'schoolDomains',
    'moduleNames',
    '$rootScope',
    '$state',
    '$cookies',
    '$translate',
    '$location',
    '$injector',
    '$q',
    '$locale',
    'AuthService',
    'RoleFactory',
    'CompanyService',
    'CompanyFactory',
    'topNavigation',
    'InventoryService',
    'ModalService',
    'SystemSettingService',
    'UserService',
    'SDS_LANGUAGES',
    function run(
      schoolDomains,
      moduleNames,
      $rootScope,
      $state,
      $cookies,
      $translate,
      $location,
      $injector,
      $q,
      $locale,
      AuthService,
      RoleFactory,
      CompanyService,
      CompanyFactory,
      topNavigation,
      InventoryService,
      ModalService,
      SystemSettingService,
      UserService,
      SDS_LANGUAGES
    ) {
      AuthService.setInitialLocation(document.location);
      $rootScope.languages = ['en'];
      $rootScope.defaultLanguage = 'en';
      $rootScope.SDS_LANGUAGES = SDS_LANGUAGES;
      $rootScope.getLanguageTitle = function (lang) {
        const langEl = $rootScope.SDS_LANGUAGES.find(el => el.value === lang);
        return langEl ? langEl.title : '';
      };

      $rootScope.majorLanguage = 'en';
      $rootScope.siteLanguage = $cookies.get('userLang') || $rootScope.languages[0];
      $rootScope.moduleNames = moduleNames;
      $rootScope.labelBatchMode = 'off';
      $rootScope.host = $location.host();
      $rootScope.reportsDropdownAccess = false;
      $rootScope.sdsRequestAccess = false;

      const redirectIfNeeded = redirectController();

      $rootScope.checkIfUserIs = RoleFactory.checkIfUserIs;
      $rootScope.checkUserSpecialAccess = UserService.checkUserSpecialAccess;
      $rootScope.showHelpModal = () => ModalService.open(help($rootScope.globalHelpType));

      $rootScope.isIe = function () {
        return (
          navigator.userAgent.indexOf('MSIE') !== -1 ||
          navigator.appVersion.indexOf('Trident/') > -1
        );
      };

      $rootScope.isMobile = function () {
        let check = false;

        (function (a) {
          if (
            screen.width <= 1099 ||
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
              a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
              a.substr(0, 4)
            )
          )
            check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);

        return check;
      };

      function haveAccess(access = '', checkLabel = true) {
        if ($rootScope.checkIfUserIs(['admin', 'sdsEditor'])) return true;

        if ($rootScope.companySettings == null) return false;

        if (checkLabel) {
          const label = $rootScope.companySettings['label' + access];

          if (!label) return false;
        }

        const accessSetting = $rootScope.companySettings['access' + access] || [];

        //inherit team member access by IP whitelested users
        let allowIPWhitelisted = $rootScope.IPallowed && ['ToBroaderSearch'].includes(access);

        for (let index = 0; index < accessSetting.length; index++) {
          const element = accessSetting[index];
          if (
            ($rootScope.currentUser && element === $rootScope.currentUser.role) ||
            (!$rootScope.currentUser && allowIPWhitelisted && element === 'teamMember')
          ) {
            return true;
          }
        }

        return false;
      }

      $rootScope.localVersion = $cookies.get('localVersion') || 'CA';

      setDefaultLegislationCode($rootScope.localVersion);
      $rootScope.defaultLegislation = getDefaultLegislation();

      $rootScope.azureBaseUrl = decodeURIComponent($cookies.get('azureBaseUrl'));
      $rootScope.googleBaseUrl = decodeURIComponent($cookies.get('googleBaseUrl'));
      $rootScope.accessLocalVersion = function (version) {
        version = !version ? [] : Array.isArray(version) ? version : [version];
        return version.includes($rootScope.localVersion);
      };
      $rootScope.systemName = (function (localVersion) {
        switch (localVersion.toLowerCase()) {
          case 'ca':
            return 'CanadaSDS';
          case 'us':
            return 'US SDS';
        }
      })($rootScope.localVersion);

      $rootScope.accessLiteQrCodes = function (nextName) {
        const liteQrCodes = ($rootScope.companySettings || {}).liteQrCodes;

        if (nextName === 'viewQRcode' && liteQrCodes) return true;

        return liteQrCodes && haveAccess('LiteQrCodes', false);
      };

      $rootScope.accessAddAttachments = function () {
        return haveAccess('AddAttachments');
      };

      $rootScope.accessModifyCustomFieldData = function () {
        return haveAccess('ModifyCustomFieldData');
      };

      $rootScope.accessEditTags = function () {
        return haveAccess('AddTags') || haveAccess('RemoveTags');
      };

      $rootScope.accessAddTags = function () {
        return haveAccess('AddTags');
      };

      $rootScope.accessRemoveTags = function () {
        return haveAccess('RemoveTags');
      };

      $rootScope.accessProductSDSOption = function () {
        return haveAccess('ProductSDSOption');
      };

      $rootScope.accessToBroaderSearch = function () {
        const broaderSearch = ($rootScope.companySettings || {}).broaderSearch;
        return broaderSearch && haveAccess('ToBroaderSearch', false);
      };

      $rootScope.accessAddSds = function () {
        const limitAddSdsAccess = ($rootScope.companySettings || {}).limitAddSdsAccess;
        return limitAddSdsAccess ? haveAccess('AddSds', false) : true;
      };

      //get the skin for this domain
      var schoolInfo = getSchoolInfo(schoolDomains);
      $rootScope.schoolInfo = schoolInfo;
      $rootScope.getSchoolName = schoolInfo.getSchoolName;
      $rootScope.skin = $rootScope.sitename = $rootScope.getSchoolName(
        $rootScope.checkIfUserIs(['admin', 'sdsEditor'])
      );

      $rootScope.isModuleEnabled = function (
        moduleName,
        checkAdminAccess = false,
        companySettings
      ) {
        if (!checkAdminAccess && $rootScope.checkIfUserIs('admin')) return true;
        if (!$rootScope.companySettings || !$rootScope.companySettings.modules) return false;

        return (companySettings || $rootScope.companySettings).modules.some(function (module) {
          return module === moduleName;
        });
      };

      $rootScope.goBack = function () {
        window.history.back();
      };

      $rootScope.isInventoryModuleAvailable = InventoryService.isModuleAvailable;

      $rootScope.topNavigation = topNavigation;
      $rootScope.navEnabled = function (navItem) {
        return navItem.cond.bind($rootScope)();
      };
      $rootScope.isActive = function (stateName) {
        if ($state.current) {
          return stateName === $state.current.name;
        } else {
          return false;
        }
      };

      function changeMainCompany(companyName, cb) {
        let res = false;
        if (companyName && $rootScope.sitename !== companyName) {
          $rootScope.sitename = companyName.toLowerCase();
          setLocalStorage('sitename', $rootScope.sitename);
          res = true;
          AuthService.getUserAndCompanyWithSettings(true).then(function () {
            cb && cb();
          });
        }
        return res;
      }

      $rootScope.onChangeMainCompany = function (companyName, restart, cb) {
        if (changeMainCompany(companyName, cb) && restart) window.location.reload();
      };

      $rootScope.changeUserPassword = function () {
        ModalService.open({
          keyboard: true,
          backdrop: true,
          controllerAs: 'ctrl',
          controller: 'changePasswordCtrl',
          templateUrl: require('./js/controllers/changePasswordCtrl/changePassword.html'),
          resolve: {}
        })
          .then(function (user) {})
          .catch(angular.noop);
      };

      $rootScope.generateUrl = function (pathname = '', id = '') {
        const origin = window.location.origin;
        return `${origin}/${pathname}/${id}`;
      };

      isImage('/images/logos/' + $rootScope.skin + '-main.png').then(function (result) {
        $rootScope.hasMainLogo = result;
      });

      //protect against adblockers
      try {
        //throws if not available
        var Analytics = $injector.get('Analytics');
      } catch (e) {
        console.error('GA is not available');
      }

      let dontRedirectToLoginAgain = false;

      // should be run only once
      SystemSettingService.showNotifications();

      initLanguages();

      const path = $location.path().replace(/^\//, '');
      if (!path && !window.PUPPETEER) {
        AuthService.getUserAndCompanyWithSettings().then(() => {
          return redirectIfNeeded({ settings: $rootScope.companySettings, path });
        });
      }

      $rootScope.$on('$stateChangeStart', function (event, next, toParams) {
        const storedUserStr = AuthService.getStoredUser();
        if (
          (storedUserStr && !AuthService.storedUserHydrated) ||
          (!storedUserStr && !AuthService.currentCompanyHydrated)
        ) {
          event.preventDefault(); //prevent current page from loading

          // restore the user from cookies or session storage
          return AuthService.getUserAndCompanyWithSettings().then(() => {
            getCompanies();
            $state.go(next, toParams);
          });
        }

        if ($rootScope.checkIfUserIs(['admin', 'sdsEditor'])) {
          $rootScope.skin = 'admin';
          changeMainCompany($rootScope.getSchoolName(true));
        }

        // redirect to login page if not logged in
        if (
          (!window.PUPPETEER && next.authenticate && !$rootScope.checkIfUserIs()) ||
          (next.adminOnly && !$rootScope.checkIfUserIs('admin'))
        ) {
          return redirectIfNeeded({ settings: $rootScope.companySettings, page: next, e: event });
        }

        const loginError = next.name === 'login' && $location.search().error;
        // if user's IP is whitelisted then redirect to the company's first page
        if (
          next.name === 'login' &&
          !window.PUPPETEER &&
          !dontRedirectToLoginAgain &&
          !loginError &&
          !$rootScope.checkIfUserIs()
        ) {
          event.preventDefault(); //prevent current page from loading
          CompanyService.isWhitelisted().then(function (bool) {
            if (bool) return goFirstPage();

            dontRedirectToLoginAgain = true;

            return $state.go('login');
          });
        }

        tryingToGoPreviewSdsPage(next, event, toParams);
        makeLogoClickable(next);
        setMainLogo(next);
        checkFirstResponder(next);
        showFooter(next);
        trackPage(next);

        if (
          next.roleAccess &&
          next.roleAccess.length &&
          !$rootScope.checkIfUserIs(next.roleAccess) &&
          (next.name !== 'sdsRequests' || !$rootScope.sdsRequestAccess)
        ) {
          event.preventDefault(); //prevent current page from loading

          return goCompanyFirstPage();
        }

        if (!$rootScope.checkIfUserIs('admin')) checkAccessToPage(next, event);
      });

      $rootScope.$on('$stateChangeSuccess', function (event, next) {
        const statesWithHelp = [
          'bulkDocumentEdit',
          'labelMaker',
          'printout',
          'labelBatchMaker',
          'linkDocument',
          'process',
          'qrcode',
          'requestSds',
          'search',
          'tags',
          'upload',
          'users',
          'viewSds',
          'settings',
          'createSupportTicket',
          'bannedChemicalList',
          'addedRemovedSdsReport',
          'bannedSearchJournal',
          'checkedDateReport',
          'fireResponseReport',
          'inventoryAuditTrail',
          'archivedSdsReport',
          'gaReports',
          'sdsListByTagReport',
          'sdsAuditLog',
          'discontinuedReport',
          'sdsSearchJournal',
          'wclReport',
          'section313Report',
          'cerclaPriorityListReport',
          'sdsRequests',
          'scheduledReports'
        ];
        $rootScope.showHelpButton = false;

        handleReportsPageAccess();

        if (statesWithHelp.includes(next.name)) {
          $rootScope.showHelpButton = true;
          $rootScope.globalHelpType =
            $rootScope.checkIfUserIs('firstResponder') && next.name === 'search'
              ? 'firstResponderSearch'
              : next.name;
        }
        if (next.name === 'viewSds' || next.name === 'labelMaker') {
          $rootScope.showDocumentToolbarToggleButton = true;
        } else {
          $rootScope.showDocumentToolbarToggleButton = false;
        }
        // collapse navbar
        angular.element('.navbar-collapse').collapse('hide');
      });

      function isImage(src) {
        var deferred = $q.defer();

        var image = new Image();
        image.onerror = function () {
          deferred.resolve(false);
        };
        image.onload = function () {
          deferred.resolve(true);
        };
        image.src = src;

        return deferred.promise;
      }

      function tryingToGoPreviewSdsPage(next, event, toParams) {
        if (next.name === 'previewSds' && !$rootScope.checkIfUserIs(['admin', 'sdsEditor'])) {
          event.preventDefault();
          return $state.go('viewSds', { id: toParams.id });
        }
      }

      function makeLogoClickable(next) {
        if (next.name !== 'search') {
          $('a.navbar-brand').attr('href', '/search');
        } else {
          $('a.navbar-brand').removeAttr('href');
        }
      }

      function setMainLogo(next) {
        if (next.name === 'logout') {
          isImage('/images/logos/' + $rootScope.skin + '-main.png').then(function (result) {
            $rootScope.hasMainLogo = result;
          });
        }
      }

      function checkFirstResponder(next) {
        // the only pages for firstResponder
        if (
          $rootScope.checkIfUserIs('firstResponder') &&
          next.name !== 'logout' &&
          next.name !== 'search' &&
          next.name !== 'viewSds' &&
          next.name !== 'resetPassword'
        ) {
          setTimeout(() => $state.go('search'));
        }
      }

      function showFooter(next) {
        if (next.name === 'process') {
          $rootScope.showFooter = false;
        } else {
          $rootScope.showFooter = true;
        }
      }

      function trackPage(next) {
        if (
          !$rootScope.checkIfUserIs(['admin', 'sdsEditor']) &&
          next.name !== 'viewSds' &&
          next.name !== 'labelMaker' &&
          next.name !== 'process'
        ) {
          window.gtag('event', 'page_view', {
            page_title: next.name
          });
        }
      }

      function checkAccessToPage(next, event) {
        let accessToPage = false;
        if (next.partOfModule) {
          switch (next.partOfModule) {
            case moduleNames.inv:
              accessToPage = $rootScope.isInventoryModuleAvailable();
              break;
            case moduleNames.hse:
              if (
                (next.name === 'qrcode' || next.name === 'viewQRcode') &&
                $rootScope.accessLiteQrCodes(next.name)
              ) {
                accessToPage = true;
              } else {
                accessToPage = $rootScope.isModuleEnabled(next.partOfModule);
              }
              break;
            default:
              accessToPage = $rootScope.isModuleEnabled(next.partOfModule);
          }
        } else {
          switch (next.name) {
            case 'requestSds':
              accessToPage = $rootScope.accessAddSds();
              break;
            default:
              accessToPage = true;
          }
        }

        if (!accessToPage) {
          event.preventDefault(); //prevent current page from loading
          goFirstPage();
        }
      }

      function initLanguages() {
        AuthService.getUserAndCompanyWithSettings().then(function ({ currentCompany }) {
          $rootScope.siteLanguage =
            $cookies.get('userLang') ||
            currentCompany.settings.defaultLanguage ||
            $rootScope.languages[0];

          for (let i = 0; i < $rootScope.languages; i++) {
            $translate.use($rootScope.siteLanguage[i]);
          }
          $translate.use($rootScope.siteLanguage);

          $rootScope.$watch('siteLanguage', function (langKey) {
            $translate.use(langKey);
            moment.locale(langKey === 'en' ? 'en-ca' : langKey);
            $locale.DATETIME_FORMATS = {
              ...$locale.DATETIME_FORMATS,
              ...getDateFormatConfigForLocale(langKey)
            };
            setTimeout(() => {
              $rootScope.$broadcast('languageChanged', langKey);
            }, 0);
          });

          $rootScope.onLanguageSwitching = function (selectedLang) {
            $rootScope.siteLanguage = selectedLang;
            $cookies.put('userLang', selectedLang);
          };
        });
      }

      function getCompanies(force) {
        if (!force && $rootScope.companies && $rootScope.companies.length) return;

        if ($rootScope.checkIfUserIs('admin')) {
          return CompanyFactory.GetCompanies(function (companies) {
            $rootScope.companies = companies;
          });
        } else {
          return $q.resolve();
        }
      }

      function goFirstPage() {
        if ($rootScope.checkIfUserIs(['admin', 'sdsEditor'])) {
          $state.go('search');
        } else if ($rootScope.checkIfUserIs()) {
          goCompanyFirstPage();
        } else {
          $state.go('search');
        }
      }

      function goCompanyFirstPage() {
        $state.go(
          ($rootScope.isModuleEnabled($rootScope.moduleNames.hse) &&
            $rootScope.companySettings.firstPage) ||
            'search'
        );
      }

      function redirectController() {
        let redirectInProgress = false;

        return function ({ settings, page = {}, e, path } = {}) {
          if (redirectInProgress) return $q.resolve(settings);

          const nextPageName = page.name || path;

          if ($rootScope.checkIfUserIs() || redirectInProgress) {
            if (!nextPageName) goFirstPage();

            redirectInProgress = false;

            return $q.resolve(settings);
          }

          redirectInProgress = true;

          return CompanyService.isWhitelisted().then(function (ipWhitelisted) {
            e && e.preventDefault(); //prevent current page from loading

            if (ipWhitelisted && !nextPageName) {
              goFirstPage();
            } else if (!ipWhitelisted && settings.azureLogin && nextPageName !== 'reset-password') {
              let azureAuthUrl =
                $rootScope.azureBaseUrl +
                '/azureAuthByCompany?companyId=' +
                $rootScope.companyId +
                '&host=' +
                $rootScope.host;

              if (nextPageName) azureAuthUrl += '&backToPage=' + nextPageName;

              window.location.href = azureAuthUrl;
            } else if (!ipWhitelisted && settings.adfsLogin && nextPageName !== 'reset-password') {
              let adfsAuthUrl =
                $rootScope.azureBaseUrl +
                '/adfsAuthByCompany?companyId=' +
                $rootScope.companyId +
                '&host=' +
                $rootScope.host;

              if (nextPageName) adfsAuthUrl += '&backToPage=' + nextPageName;

              window.location.href = adfsAuthUrl;
            } else if (
              !ipWhitelisted &&
              settings.googleLogin &&
              nextPageName !== 'reset-password'
            ) {
              let googleAuthUrl =
                $rootScope.googleBaseUrl +
                '/googleAuthByCompany?companyId=' +
                $rootScope.companyId +
                '&host=' +
                $rootScope.host;

              if (nextPageName) googleAuthUrl += '&backToPage=' + nextPageName;

              window.location.href = googleAuthUrl;
            } else if (
              !ipWhitelisted &&
              nextPageName &&
              nextPageName !== 'reset-password' &&
              page.authenticate
            ) {
              $rootScope.returnToState = nextPageName;
              $state.go('login');
            } else if (!nextPageName) {
              $state.go('login');
            }

            redirectInProgress = false;

            return settings;
          });
        };
      }

      function setLocalStorage(param, value) {
        window.localStorage.setItem(param, value);
      }

      function getLocalStorage(param) {
        return window.localStorage.getItem(param);
      }

      function getSchoolInfo(domains) {
        var schools = Object.keys(domains);

        return {
          getSchoolName: function (admin) {
            var schoolName;

            if (admin) schoolName = getLocalStorage('sitename');

            if (!schoolName) {
              schools.some(function (school) {
                if (~domains[school].indexOf(window.location.hostname)) {
                  schoolName = school;
                  return true;
                }
                return false;
              });
            }

            return schoolName;
          },
          getSchoolDomainsByName: function (schoolName) {
            return domains[schoolName];
          },
          getSchoolDomain: function (schoolName) {
            const name = schoolName.toLowerCase();
            const websiteLocalVersion = $rootScope.localVersion;
            const schoolDomains = domains[name];

            if (schoolDomains.length === 1) return schoolDomains[0];

            let mainDomainName = 'canadasds';
            switch ($rootScope.localVersion) {
              case 'CA':
                mainDomainName = 'canadasds';
                break;
              case 'US':
                mainDomainName = 'ussds';
                break;
            }

            return schoolDomains.find(domainName => domainName.includes(mainDomainName));
          }
        };
      }

      function handleReportsPageAccess() {
        AuthService.getUserAndCompanyWithSettings().then(function ({ currentCompany }) {
          $rootScope.reportsDropdownAccess = $rootScope.checkIfUserIs([
            'admin',
            'teamAdmin',
            ...(currentCompany.settings.accessAddTags || [])
          ]);
          $rootScope.sdsRequestAccess = $rootScope.checkIfUserIs([
            'admin',
            'teamAdmin',
            ...(currentCompany.settings.accessAddTags || [])
          ]);
        });
      }
    }
  ])
  .service('LanguageService', [
    '$rootScope',
    function ($rootScope) {
      this.getCurrentLanguage = function () {
        return $rootScope.siteLanguage;
      };
    }
  ]);
